<template>
  <div class="content">
    <div class="main">
      <div>
        <img src="../../assets/images/404.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang='less'>
.main {
  width: 100%;
  height: 100%;
  margin: auto;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
